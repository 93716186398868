<script>
    export default {
        name: "AboutPage",
    };
</script>

<template>
    <div>
        <h2>About</h2>
    </div>
</template>
